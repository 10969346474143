
import Vue from "vue";

import Field from "@/components/Field.vue";
import SelectField from "@/components/SelectField.vue";
import EditTree from "@/components/variables/EditTree.vue";
import Create from "@/components/variables/Create.vue";

import RCP from "@/api/RCP.ts";

import { Version, VersionResponse } from "@/types/Version";
import { ErrorResponse } from "@/types/Errors";

export default Vue.extend({
  name: "variables",
  data() {
    return {
      variables: [] as any,
      request: false,
      routeCheck: false,
      watcherId: () => false as any,
    };
  },
  created() {
    this.createTree();
  },
  computed: {
    currentVersion(): Version {
      return this.$store.state.template.version;
    },
    variablesLastId(): number {
      return this.$store.state.variables.lastId;
    },
  },
  watch: {
    currentVersion() {
      this.createTree();
    },
  },
  methods: {
    createTree() {
      const that = this;

      this.routeCheck = false;
      this.watcherId();
      this.watcherId = () => false;

      const variables = this.$store.state.template.version.variables;

      function createArray(arr: any): any {
        const newArr: any = [];

        if (arr !== undefined) {
          Object.keys(arr).forEach((index: any, num: any) => {
            let defaultValue: any = arr[index].default_value;

            if (defaultValue !== null) {
              defaultValue =
                arr[index].type === "money"
                  ? arr[index].default_value / 100
                  : arr[index].default_value;
            } else {
              defaultValue = "";
            }

            let exampleValue: any = arr[index].example_value;

            if (exampleValue !== null) {
              exampleValue =
                arr[index].type === "money"
                  ? arr[index].example_value / 100
                  : arr[index].example_value;
            } else {
              exampleValue = "";
            }

            newArr.push({
              id: that.variablesLastId,
              index,
              ...arr[index],
              default_value: defaultValue,
              example_value: exampleValue,
              properties: [],
            });

            that.$store.commit("variables/updateId");

            if (["object", "[]object"].indexOf(arr[index].type) !== -1) {
              newArr[num].properties = createArray(arr[index].properties);
            }
          });
        }

        return newArr;
      }

      this.variables = createArray(variables);

      setTimeout(() => {
        this.watcherId = this.$watch(
          "variables",
          () => {
            this.routeCheck = true;
            this.watcherId();
          },
          {
            deep: true,
          }
        );
      }, 0);
    },
    add(variable: any) {
      this.variables.push(variable);
    },
    deleteVariable(index: any) {
      this.variables.splice(index, 1);
    },
    saveVersion(flag = false) {
      return new Promise((resolve, reject) => {
        if (!this.request) {
          this.request = true;

          function ObjectsArr(
            object: any,
            type: string = "default_value",
            isObj: boolean = false
          ): any {
            const returned: any = {};

            Object.keys(object).forEach((key: any) => {
              if (["object", "[]object"].indexOf(object[key].type) !== -1) {
                returned[key] = ObjectsArr(object[key].properties, type, true);
              } else {
                returned[key] = object[key][type];
              }
            });

            if (isObj) {
              return returned;
            }

            return [returned];
          }

          function createObject(arr: any): any {
            const newArr: any = {};

            if (arr !== undefined) {
              arr.forEach((variable: any) => {
                let defaultValue: any = variable.default_value;
                let exampleValue: any = variable.example_value;

                if (defaultValue !== "") {
                  if (variable.type === "money") {
                    defaultValue *= 100;
                  } else if (["float", "int"].indexOf(variable.type) !== -1) {
                    defaultValue *= 1;
                  } else if (variable.type === "datetime") {
                    defaultValue = defaultValue
                      ? new Date(defaultValue).toISOString()
                      : "";
                  } else if (variable.type === "[]object") {
                    const object = createObject(variable.properties);
                    defaultValue = ObjectsArr(object);
                  }
                } else {
                  defaultValue = null;
                }

                if (exampleValue !== "") {
                  if (variable.type === "money") {
                    exampleValue *= 100;
                  } else if (["float", "int"].indexOf(variable.type) !== -1) {
                    exampleValue *= 1;
                  } else if (variable.type === "datetime") {
                    exampleValue = exampleValue
                      ? new Date(exampleValue).toISOString()
                      : "";
                  } else if (variable.type === "[]object") {
                    const object = createObject(variable.properties);
                    exampleValue = ObjectsArr(object, "example_value");
                  }
                } else {
                  exampleValue = null;
                }

                newArr[variable.index] = {
                  description: variable.description,
                  type: variable.type,
                  required: variable.required,
                  default_value: defaultValue,
                  example_value: exampleValue,
                };

                if (["object", "[]object"].indexOf(variable.type) !== -1) {
                  newArr[variable.index].properties = createObject(
                    variable.properties
                  );
                }
              });
            }

            return newArr;
          }

          const variables: any = createObject(this.variables);

          RCP({
            method: "App.Template.Version.set",
            params: {
              attr: {
                app_id: this.currentVersion.app_id,
                template_id: this.currentVersion.template_id,
                variables,
              },
            },
            id: "saveVersion",
          })
            .then((result: VersionResponse) => {
              this.request = false;

              if (result.item) {
                if (!flag) {
                  alert("Сохранено : Переменные успешно обновлены");
                }

                this.$store.commit("template/setVersion", result.item);
                resolve();
              } else {
                if (!flag) {
                  alert(
                    "Ошибка : Произошла ошибка при попытке сохранить переменные"
                  );
                }
                reject();
              }
            })
            .catch((error: ErrorResponse) => {
              if (error.show) {
                alert("Ошибка : " + error.message);
              }

              this.request = false;
              reject();
            });
        } else {
          reject();
        }
      });
    },
    checkChanges() {
      return new Promise((resolve) => {
        if (this.routeCheck) {
          if (
            !confirm(
              "Внесены изменения : Сохранить внесенные изменения перед переходом на другую страницу?"
            )
          ) {
            resolve();
          } else {
            return this.saveVersion(true)
              .then(() => {
                resolve();
              })
              .catch(() => {
                alert("Произошла ошибка");
              });
          }
        } else {
          resolve();
        }
      });
    },
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    this.checkChanges().then(next);
  },
  components: {
    Field,
    SelectField,
    EditTree,
    Create,
  },
});
