
    import Vue from 'vue';

    import Field from '@/components/Field.vue';
    import SelectField from '@/components/SelectField.vue';

    import options from './options';

    import Validator from '@/helpers/validator';

    export default Vue.extend({
        name: 'create',
        props: {
            properties: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                form: {
                    index: '',
                    description: '',
                    type: 'string',
                    required: false,
                    default_value: '',
                    example_value: ''
                },
                formRules: {
                    index: 'required'
                },
                errors: {
                    index: ''
                },
                formRequest: false,
                watcher: () => false as any,
                options
            };
        },
        methods: {
            validate(submit = true) {
                const validate = new Validator(this.form, this.formRules).make();

                this.errors = validate.errors;

                const exist = this.exists.indexOf(this.form.index) !== -1;

                if (validate.isValid && submit && !exist) {
                    this.add();
                } else {
                    this.formRequest = true;
                }

                if (exist) {
                    this.errors.index = 'Такой индекс уже занят';
                }
            },
            add() {
                this.$emit('add', {
                    ...this.form,
                    id: this.variablesLastId,
                    properties: []
                });
                this.formRequest = false;
                this.$store.commit('variables/updateId');

                this.form = {
                    index: '',
                    description: '',
                    type: 'string',
                    required: false,
                    default_value: '',
                    example_value: ''
                };
            }
        },
        computed: {
            exists(): any {
                if (!this.properties) {
                    return -1;
                }

                return this.properties.map((item: any) => {
                    return item.index;
                });
            },
            variablesLastId(): number {
                return this.$store.state.variables.lastId;
            }
        },
        watch: {
            formRequest(val) {
                if (val) {
                    this.watcher = this.$watch('form', () => {
                        this.validate(false);
                    }, {
                        deep: true
                    });
                } else {
                    this.watcher();
                }
            }
        },
        components: {
            Field,
            SelectField
        }
    });
