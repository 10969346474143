
    import Vue from 'vue';

    import Field from '@/components/Field.vue';
    import SelectField from '@/components/SelectField.vue';
    import Create from '@/components/variables/Create.vue';
    import DateTimePicker from '@/components/DateTimePicker.vue';
    import DatePicker from '@/components/DatePicker.vue';
    import SelectBoolean from '@/components/SelectBoolean.vue';

    import options from './options';

    export default Vue.extend({
        name: 'EditTree',
        props: {
            variable: {
                type: Object,
                required: true
            },
            index: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                options
            };
        },
        computed: {
            isNum(): boolean {
                return ['money', 'int', 'float'].indexOf(this.variable.type) > -1;
            },
            isOnlyNum(): boolean {
                return ['int'].indexOf(this.variable.type) > -1;
            },
            isDateTime(): boolean {
                return this.variable.type === 'datetime';
            },
            isDate(): boolean {
                return this.variable.type === 'date';
            },
            isBool(): boolean {
                return this.variable.type === 'bool';
            }
        },
        watch: {
            'variable.type'() {
                this.variable.default_value = '';
                this.variable.example_value = '';
            }
        },
        methods: {
            add(variable: any) {
                if (this.variable.properties === undefined) {
                    this.$set(this.variable, 'properties', []);
                }

                this.variable.properties.push(variable);
            },
            deleteVariable(index: any) {
                this.variable.properties.splice(index, 1);
            },
            deleteVar(index: any) {
                this.$emit('delete', index);
            }
        },
        components: {
            Field,
            SelectField,
            Create,
            DateTimePicker,
            DatePicker,
            SelectBoolean
        }
    });
